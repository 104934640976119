// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { LIGHT_THEME, DARK_THEME } from './theme';

import Home from './pages/Home';
import NilResources from './pages/NilResources';
import Blog from './pages/Blog';
import Login from './pages/Login';
import HowItWorks from './pages/HowItWorks';
import ConnectWithAPro from './pages/ConnectWithAPro';
import Account from './pages/Account';
import SignUp from './pages/SignUp';

import ProtectedRoute from './components/ProtectedRoute';
import Feed from './pages/Feed';

// Import ThemeContext and ThemeProviderCustom
import { ThemeContext, ThemeProviderCustom } from './ThemeContex';
import Search from './pages/Search';
import CouroAI from './pages/CouroAI';
import Announcements from './pages/Announcements';
import Settings from './pages/Settings';
import WorkoutBuilder from './pages/WorkoutBuilder';
import GlobalStyle from './GlobalStyles';

import UserProfile from './pages/UserProfile'; 

const App: React.FC = () => {
  return (
    <ThemeProviderCustom>
      <ThemeContext.Consumer>
        {({ isDarkMode }) => (
          <ThemeProvider theme={isDarkMode ? DARK_THEME : LIGHT_THEME}>
            <GlobalStyle />
            <Router>
              <div style={{ padding: 0, margin: 0 }}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/connect" element={<ConnectWithAPro />} />
                  <Route path="/how" element={<HowItWorks />} />
                  <Route path="/nil" element={<NilResources />} />
                  <Route path="/blog" element={<Blog />} />
                  {/* Protected route for Account */}
                  <Route
                    path="/account"
                    element={
                      <ProtectedRoute>
                        <Account />
                      </ProtectedRoute>
                    }
                  />
                  {/* Protected route for Search */}
                  <Route
                    path="/search"
                    element={
                      <ProtectedRoute>
                        <Search />
                      </ProtectedRoute>
                    }
                  />
                  {/* Protected route for Feed */}
                  <Route
                    path="/feed"
                    element={
                      <ProtectedRoute>
                        <Feed />
                      </ProtectedRoute>
                    }
                  />
                  {/* Protected route for CouroAI */}
                  <Route
                    path="/couro-ai"
                    element={
                      <ProtectedRoute>
                        <CouroAI />
                      </ProtectedRoute>
                    }
                  />
                  {/* Protected route for Announcements */}
                  <Route
                    path="/announcements"
                    element={
                      <ProtectedRoute>
                        <Announcements />
                      </ProtectedRoute>
                    }
                  />
                  {/* Protected route for Settings */}
                  <Route
                    path="/settings"
                    element={
                      <ProtectedRoute>
                        <Settings />
                      </ProtectedRoute>
                    }
                  />
                  {/* Protected route for WorkoutBuilder */}
                  <Route
                    path="/workout-builder"
                    element={
                      <ProtectedRoute>
                        <WorkoutBuilder />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/:username/"
                    element={
                      <ProtectedRoute>
                        <UserProfile />
                      </ProtectedRoute>
                    }
                  />
                </Routes>
              </div>
            </Router>
          </ThemeProvider>
        )}
      </ThemeContext.Consumer>
    </ThemeProviderCustom>
  );
};

export default App;
