// src/components/Logo.tsx
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Link } from 'react-router-dom'; 

// Import both logo images
import LogoDark from '../assets/Couro_dark.png'; 
import LogoLight from '../assets/Couro_light.png'; 

const LogoContainer = styled.div`
  text-align: center;
`;

const LogoImage = styled.img`
  width: 70px;
  height: 60px;
`;

const LoggedInLogo: React.FC = () => {
  // Access the current theme
  const theme = useTheme();

  // Determine if the current theme is dark mode
  const isDarkMode = theme.mode === 'dark';

  // Select the logo source based on the theme
  const logoSrc = isDarkMode ? LogoLight : LogoDark;

  return (
    <LogoContainer>
      <Link to="/feed"> 
        <LogoImage src={logoSrc} alt="Logo" />
      </Link>
    </LogoContainer>
  );
};

export default LoggedInLogo;
