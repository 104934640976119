// pages/Home.tsx
import React from 'react';
import styled from 'styled-components';
import { SignedIn, SignedOut } from '@clerk/clerk-react';
import { Navigate } from 'react-router-dom';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import HeroSection from '../components/HomePage/HeroSection';
import WhyAnalyzeSection from '../components/HomePage/WhyAnalyzeSection';

const HomeContainer = styled.div`
  height: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding-top: 2rem;
`;

const Home: React.FC = () => {
  return (
    <>
      {/* Redirect to /account if the user is signed in */}
      <SignedIn>
        <Navigate to="/account" replace />
      </SignedIn>

      {/* Display Home page if the user is signed out */}
      <SignedOut>
        <HomeContainer>
          <Navbar />
          <HeroSection />
          <WhyAnalyzeSection />
          <h1>Welcome to the Home Page</h1>
        </HomeContainer>
        <Footer />
      </SignedOut>
    </>
  );
};

export default Home;
