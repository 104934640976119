// src/data/authors.ts
import HazemJweinatIcon from '../assets/ProfilePics/Hazem_Jweinat.jpg';
import JamieMcArthurIcon from '../assets/ProfilePics/Jamie_McArthur.jpg';
import DonaldJacksonIcon from '../assets/ProfilePics/Donald_Jackson.jpg';
import BrendaBakerIcon from '../assets/ProfilePics/Brenda_Baker.jpg';
import JessieHansleyIcon from '../assets/ProfilePics/Jessie_Hansley.jpg';
import BrandonJacobsIcon from '../assets/ProfilePics/Brandon_Jacobs.jpg';
import JennieJacobsIcon from '../assets/ProfilePics/Jennie_Jacobs.jpg';
import JessicaMichaelsIcon from '../assets/ProfilePics/Jessica_Michaels.jpg';

// Define the Author interface
export interface Author {
  id: number;
  username: string;
  iconUrl: string;
  isPro?: boolean;
}

// Shared author data
export const authors: Author[] = [
  { id: 1, username: 'HazemJweinat', iconUrl: HazemJweinatIcon },
  { id: 2, username: 'JamieMcArthur', iconUrl: JamieMcArthurIcon },
  { id: 3, username: 'DonaldJackson', iconUrl: DonaldJacksonIcon },
  { id: 4, username: 'BrendaBaker', iconUrl: BrendaBakerIcon },
  { id: 5, username: 'JessieHansley', iconUrl: JessieHansleyIcon },
  { id: 6, username: 'BrandonJacobs', iconUrl: BrandonJacobsIcon, isPro: true },
  { id: 7, username: 'JennieJacobs', iconUrl: JennieJacobsIcon, isPro: true },
  { id: 8, username: 'JessicaMichaels', iconUrl: JessicaMichaelsIcon, isPro: true },
];
