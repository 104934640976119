import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { authors, Author } from '../../data/authors';

// Loading skeleton animation
const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

// Skeleton Styles
const SkeletonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding-top: 1rem;
`;

const SkeletonCircle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ddd;
  background-image: linear-gradient(90deg, #ddd 0px, #e1e1e1 40px, #ddd 80px);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite linear;
  margin-right: 10px;
  margin-left: 2rem;
`;

const SkeletonText = styled.div`
  width: 100px;
  height: 20px;
  background: #ddd;
  background-image: linear-gradient(90deg, #ddd 0px, #e1e1e1 40px, #ddd 80px);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite linear;
  border-radius: 4px;
`;

const SkeletonContent = styled.div`
  width: 100%;
  height: 500px;
  background: #ddd;
  background-image: linear-gradient(90deg, #ddd 0px, #e1e1e1 40px, #ddd 80px);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite linear;
  border-radius: 8px;
  margin-bottom: 1rem;
`;

// Styled Components for Post
const PostContainer = styled.div`
  width: 92%;
  min-height: 600px;
  background-color: ${({ theme }) => theme.feedPost};
  border-radius: 16px;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  border: 0.5px solid;
  border-color: ${({ theme }) => theme.feedBorder};

  @media (max-width: 768px) {
    width: 100%;
    background-color: transparent;
    border: 1px solid rgba(225, 225, 225, 0.5);
    border-radius: 0;
  }
`;

const PostTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ theme }) => theme.textColor};
  text-align: left;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(200, 200, 200, 0.3);
  margin: 0;
`;

const ContentArea = styled.div`
  background-color: rgb(100, 100, 100);
  width: 100%;
  height: 80%;
  margin-bottom: 1rem;
`;

const UserPost = styled.div`
  text-align: left;
  font-size: 1rem;
  padding-bottom: 3rem;
  color: ${({ theme }) => theme.textColor};
`;

const AuthorInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AuthorIconStyled = styled.img<{ isPro?: boolean }>`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 2rem;
  border: ${({ isPro }) => (isPro ? '3px solid gold' : 'none')};
  box-sizing: border-box;
`;

const AuthorUserName = styled.p`
  font-size: 1.2em;
  font-weight: bold;
  color: ${({ theme }) => theme.textColor || '#000'};
`;

const HeartIcon = styled.div`
  cursor: pointer;
  margin-right: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: red;
  transition: color 0.3s ease;

  &:hover {
    color: darkred;
  }
`;

const Post: React.FC = () => {
  const [author, setAuthor] = useState<Author | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLiked, setIsLiked] = useState<boolean>(false);

  useEffect(() => {
    const fetchAuthor = () => {
      setTimeout(() => {
        try {
          const randomAuthor = authors[Math.floor(Math.random() * authors.length)];
          setAuthor(randomAuthor);
        } catch (err) {
          setError('Failed to load author data.');
        }
      }, 1000);
    };

    fetchAuthor();
  }, []);

  return (
    <PostContainer>
      <PostTitle>
        {error ? (
          <span>{error}</span>
        ) : author ? (
          <>
            <AuthorInfoContainer>
              <AuthorIconStyled src={author.iconUrl} alt={`${author.username}'s icon`} isPro={author.isPro} />
              <AuthorUserName>{author.username}</AuthorUserName>
            </AuthorInfoContainer>
            <HeartIcon onClick={() => setIsLiked(!isLiked)}>
              {isLiked ? <FaHeart /> : <FaRegHeart />}
            </HeartIcon>
          </>
        ) : (
          <SkeletonContainer>
            <SkeletonCircle />
            <SkeletonText />
          </SkeletonContainer>
        )}
      </PostTitle>

      <Separator />

      {author ? (
        <>
          <ContentArea>{/* Replace with actual content */}</ContentArea>
          <UserPost>This is where the user's post content will appear.</UserPost>
        </>
      ) : (
        <SkeletonContent />
      )}
    </PostContainer>
  );
};

export default Post;
