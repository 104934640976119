// src/pages/Settings.tsx

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import AccountNavbar from '../components/AccountNavbar';
import { useUser } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';


// Styled Components
const SearchContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.feedBackground};
  color: ${({ theme }) => theme.textColor};
`;

const SettingsFullContainer = styled.div`
  display: flex;
  flex-direction: column;
    width: 100vw;
  height: 100vh;
`;
const SettingsHeader = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .1rem 2.5rem;
`;

const SettingsTitle = styled.h1`
  font-weight: bold;
`;
const SettingsListContainer = styled.div`
  border-top: .3rem solid ${({ theme }) => theme.feedBorder}; 
  height: auto;
  width: 100%;
  flex-direction: column;
  margin-top: 1.2rem;
`;
const SettingsList = styled.div`
  height: auto;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: flex-start;
  padding: 1.8rem 1.2rem;
  border-bottom: .3rem solid ${({ theme }) => theme.feedBorder};
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: inset 0 0 9px ${({ theme }) => theme.feedBorder};
    cursor: pointer;
  }
`;

const SettingsText = styled.p`
  font-weight: bold;
  font-size: 1.3rem;
`;
const Settings: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  // Access user data from Clerk
  const { isLoaded, isSignedIn } = useUser();

  // Redirect to sign-in if not authenticated
  useEffect(() => {
    if (isLoaded && !isSignedIn) {
      navigate('/sign-in');
    }
  }, [isLoaded, isSignedIn, navigate]);

  const toggleNavbar = () => {
    setIsExpanded(!isExpanded);
  };

  // Check if user is loaded
  if (!isLoaded) {
    return <div>Loading...</div>; // Or a spinner/loading indicator
  }

  return (
    <SearchContainer>
      <AccountNavbar isExpanded={isExpanded} toggleNavbar={toggleNavbar} />
      <SettingsFullContainer>
      <SettingsHeader>
        <SettingsTitle>Settings</SettingsTitle>
      </SettingsHeader>
      <SettingsListContainer>
        <SettingsList><SettingsText>F.A.Q.</SettingsText></SettingsList>
        <SettingsList><SettingsText>Support</SettingsText></SettingsList>
        <SettingsList><SettingsText>Help</SettingsText></SettingsList>
        <SettingsList><SettingsText>Patch Notes</SettingsText></SettingsList>
      </SettingsListContainer>
      </SettingsFullContainer>
    </SearchContainer>
  );
};


export default Settings;
