import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Link } from 'react-router-dom'; 
import LogoLightSrc from './../assets/Couro_light.png';
import LogoDarkSrc from './../assets/Couro_dark.png';

const LogoContainer = styled.div`
  text-align: center;
  padding: 1rem;
  margin-left: 1rem;
`;

const LogoImage = styled.img`
  width: 110px;
  height: 70px;
`;

const Logo: React.FC = () => {
  const theme = useTheme();

  // Select the correct logo based on theme mode
  const logoSrc = theme.mode === 'dark' ? LogoLightSrc : LogoDarkSrc;

  return (
    <LogoContainer>
      <Link to="/"> 
        <LogoImage src={logoSrc} alt="Logo" />
      </Link>
    </LogoContainer>
  );
};

export default Logo;
