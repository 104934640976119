// src/components/FollowingList.tsx
import React from 'react';
import styled from 'styled-components';
import { authors } from '../../data/authors';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const FollowingListContainer = styled.div`
  width: 100%;
  height: 110px;
  display: flex;
  overflow-x: auto;
  align-items: center;
  padding: 0 2rem;
  box-sizing: border-box;
  justify-content: center;

  /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
`;

const ProfileWrapper = styled.div`
  display: flex;
  min-width: max-content; /* Ensures children don’t shrink below their content width */
`;

const ProfileCircle = styled.img<{ isPro?: boolean }>`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
  transition: transform 0.3s;
  border: ${({ isPro }) => (isPro ? '3px solid gold' : 'none')};

  &:hover {
    transform: scale(1.05);
  }
`;

const FollowingList: React.FC = () => {
  const navigate = useNavigate(); // Initialize navigate

  const handleProfileClick = (username: string) => {
    navigate(`/${username}/`); // Navigate to the user's profile
  };

return (
    <FollowingListContainer>
      <ProfileWrapper>
        {authors
          .sort((a, b) => (b.isPro ? 1 : 0) - (a.isPro ? 1 : 0)) // Sort pros first
          .map((author, index) => (
            <ProfileCircle
              key={index}
              src={author.iconUrl}
              alt={`${author.username}'s icon`}
              isPro={author.isPro}
              onClick={() => handleProfileClick(author.username)} // Add onClick handler
            />
          ))}
      </ProfileWrapper>
    </FollowingListContainer>
  );
};

export default FollowingList;
