// src/ThemeContext.tsx
import React, { createContext, useState, useEffect, ReactNode } from 'react';

type ThemeMode = 'system' | 'light' | 'dark';

interface ThemeContextType {
  isDarkMode: boolean;
  themeMode: ThemeMode;
  setThemeMode: (mode: ThemeMode) => void;
}

export const ThemeContext = createContext<ThemeContextType>({
  isDarkMode: false,
  themeMode: 'system',
  setThemeMode: () => {},
});

interface ThemeProviderCustomProps {
  children: ReactNode;
}

export const ThemeProviderCustom = ({ children }: ThemeProviderCustomProps) => {
  const [themeMode, setThemeMode] = useState<ThemeMode>(() => {
    const storedTheme = localStorage.getItem('themeMode');
    if (storedTheme === 'light' || storedTheme === 'dark' || storedTheme === 'system') {
      return storedTheme as ThemeMode;
    }
    return 'system';
  });

  const [isDarkMode, setIsDarkMode] = useState<boolean>(() => {
    if (themeMode === 'light') {
      return false;
    } else if (themeMode === 'dark') {
      return true;
    } else {
      return window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
  });

  useEffect(() => {
    localStorage.setItem('themeMode', themeMode);
  }, [themeMode]);

  useEffect(() => {
    if (themeMode === 'system') {
      const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

      const systemThemeChangeListener = (e: MediaQueryListEvent) => {
        setIsDarkMode(e.matches);
      };

      // Add listener for changes in the system theme
      darkModeMediaQuery.addEventListener('change', systemThemeChangeListener);

      // Clean up listener on component unmount or when themeMode changes
      return () => {
        darkModeMediaQuery.removeEventListener('change', systemThemeChangeListener);
      };
    }
  }, [themeMode]);

  useEffect(() => {
    if (themeMode === 'light') {
      setIsDarkMode(false);
    } else if (themeMode === 'dark') {
      setIsDarkMode(true);
    } else if (themeMode === 'system') {
      setIsDarkMode(window.matchMedia('(prefers-color-scheme: dark)').matches);
    }
  }, [themeMode]);

  return (
    <ThemeContext.Provider value={{ isDarkMode, themeMode, setThemeMode }}>
      {children}
    </ThemeContext.Provider>
  );
};
