// pages/HowItWorks.tsx
import React from 'react';
import styled from 'styled-components';
import Navbar from '../components/Navbar';

const NilResources: React.FC = () => {

  const HelpContainer = styled.div`
  min-height: 700px; /* Adjust this based on typical smartphone height */
  display: flex;
  flex-direction: column; /* Align content vertically */
  justify-content: flex-start; /* Align content at the top */
  align-items: center; /* Center horizontally */
  text-align: center;
  padding-top: 2rem; /* Add padding at the top */
`;

  const Heading = styled.h1`
  color: rgb(0,0,0);
  margin-top: 1rem;
  font-size: 1.2rem;
  `;
  const Section = styled.div`
  width: 100%;
  height: 400px;
  padding: 4px;
  `
  return (
    <HelpContainer>
      <Navbar />
      <Heading>Resources in Name, Image, and Likeness for
      Student-Athletes</Heading>
      <Section>Understand NIL Rules
There are many restrictions and limitations when it comes to NCAA rules around NIL, not only about what you can and can’t do but also about income reporting from NIL activities.
Check out this article in our Insights blog to stay up to date with the latest regulations.</Section>
      <Section>Student-Athletes in Personal Branding
Building your personal brand is key when attracting sponsorship and endorsement deals. It’s important to stay consistent and creative with your social media presence, have a personal website, and to highlight your achievements and unique interests.
Check out this free video series to learn more about ways to hack various social media algorithms.</Section>
      <Section>Networking in the Sports Community
Half the battle of mastering your NIL opportunities is knowing the right people. At Couro, we’re here to help you build your community so you grow your relationships with other athletes, coaches, industry professionals, potential partners, and sponsors.
Check out this article in our Insights blog for a list of resources, online communities, and upcoming events in the space.</Section>
      <Section>Legal and Financial Advice
It’s extremely important to make informed decisions and to work with a lawyer who can help you protect your interest. Consider your options when reviewing contracts, understanding tax implications, and managing your finances.
Did you know our CEO is a practicing lawyer in California and a professional track and field athlete? Click here to reach out to him directly with any questions you may have. We’re here to provide any free support we can on your journey.</Section>
      <Section>Online NIL Platforms
There are several online platforms that are dedicated to connecting student-athletes with potential partners and helping you navigate those relationships. Often, they help you create and distribute content, find and manage sponsorships, and track your income.
Check out this article in our Insights blog for a list of online platforms.</Section>
      <Section>For other Name, Image, and Likeness resources, check out these articles:
10 Must Know NIL Contract Tips
NIL Is Coming to a Highschool Near You
What Are NIL Collectives and Are They Too Good To Be True?
NCAA Name, Image, and Likeness Scouting Report</Section>
    </HelpContainer>
  );
};

export default NilResources;
