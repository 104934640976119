import React, { useState } from 'react';
import styled from 'styled-components';
import reduceInjury from '../../assets/WhyAnalze/ReduceInjury.png';
import increasedSpeed from '../../assets/WhyAnalze/IncreasedSpeed.png';
import improvedJointHealth from '../../assets/WhyAnalze/ImprovedJointHealth.png';
import betterBalance from '../../assets/WhyAnalze/BetterBalance.png';
import enhancedMuscleActivation from '../../assets/WhyAnalze/EnhancedMuscleActivation.png';
import energyConservation from '../../assets/WhyAnalze/EnergyConservation.png';


const SectionContainer = styled.div`
  height: 400px;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem 1rem 4rem 1rem;
  background-color: rgb(50,50,50);
`;

const HeaderContainer = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  color: gold;
`;

const BottomSection = styled.div`
  height: 330px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const LeftContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .circle-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    @media (min-width: 768px) and (max-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  }

  .image {
    width: 75px;
    height: 75px;
    cursor: pointer;
    margin: 0.7rem;
    border-radius: 50%;
  }
`;

const RightContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .card {
    width: 80%;
    height: 300px;
    border: 1.5px solid rgb(180, 180, 180);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.5rem;
    opacity: 0;
    transition: opacity 0.5s ease;
    background-color: rgba(150, 150, 150, 0.2);
    padding: 1rem;

    @media (min-width: 1024px) {
      width: 70%;
      height: 270px;
    }

    p {
      margin: 0;
      font-size: .9rem;
      color: lightgray;
    }
      .separator {
      width: 100%;
      height: 1px;
      background-color: rgba(200, 200, 200, 0.2);
      margin: 1rem 0;
    }
  }

  .card.show {
    opacity: 1;
  }
`;


const Heading = styled.h1`
  margin-bottom: 1rem;
  padding-horizontal: 3rem;
  font-size: 2.5rem;
`;


const WhyAnalyzeSection: React.FC = () => {
  const [selectedCircle, setSelectedCircle] = useState<number | null>(null);

  const handleCircleClick = (circleNumber: number) => {
    setSelectedCircle(circleNumber);
  };

  const images = [
    { src: reduceInjury, name: 'Reduce Injury', description: 'Good posture minimizes the risk of injuries by distributing the impact forces evenly across your body. It helps prevent common running injuries like shin splints, knee pain, and lower back issues by reducing strain on muscles and joints.' },
    { src: increasedSpeed, name: 'Increased Speed', description: 'Aims to increase your running speed efficiently.' },
    { src: improvedJointHealth, name: 'Improved Joint Health', description: 'Supports better joint health during runs.' },
    { src: betterBalance, name: 'Better Balance', description: 'Improves your overall balance while running.' },
    { src: enhancedMuscleActivation, name: 'Enhanced Muscle Activation', description: 'Optimal running angles ensure that the correct muscle groups are activated and engaged, promoting balanced muscle development and reducing the risk of muscle imbalances.' },
    { src: energyConservation, name: 'Energy Conservation', description: 'Helps conserve energy during long runs.' },
  ];


  return (
    <SectionContainer>
      <HeaderContainer>
        <Heading>Why Analyze Your Run?</Heading>
      </HeaderContainer>
      <BottomSection>
      <LeftContainer>
          <div className="circle-container">
            {images.map((image, index) => (
              <img
                key={index}
                src={image.src}
                alt={`Circle ${index + 1}`}
                className="image"
                onClick={() => handleCircleClick(index + 1)}
              />
            ))}
          </div>
        </LeftContainer>
        <RightContainer>
          {selectedCircle && (
            <div className={`card ${selectedCircle ? 'show' : ''}`}>
            <h3>{images[selectedCircle - 1].name}</h3>
            <div className="separator"></div>
            <p>{images[selectedCircle - 1].description}</p>
          </div>
          )}
        </RightContainer>
      </BottomSection>
    </SectionContainer>
  );
};

export default WhyAnalyzeSection;