// ProtectedRoute.tsx
import React from 'react';
import { RedirectToSignIn, SignedIn, SignedOut } from '@clerk/clerk-react';

interface ProtectedRouteProps {
  children: React.ReactNode;
  redirectUrl?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, redirectUrl = "/login" }) => {
  return (
    <>
      <SignedIn>{children}</SignedIn>
      <SignedOut>
        <RedirectToSignIn redirectUrl={redirectUrl} />
      </SignedOut>
    </>
  );
};

export default ProtectedRoute;
