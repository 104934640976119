import React, { useState } from 'react';
import styled from 'styled-components';
import AccountNavbar from '../components/AccountNavbar';
import { FaCamera, FaPlus, FaThList, FaBookmark, FaCrown } from 'react-icons/fa';
import { useClerk, useUser } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';


const AccountContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.feedBackground};
`;

const ScrollableFeed = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.feedBackground};
`;

/* Account Header Section */
const AccountHeaderSection = styled.div`
  width: 80%;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2.5rem 3rem 1rem 3rem;
`;

/* Profile Picture */
const ProfilePic = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.feedBorder};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  margin-right: 1rem;
  
`;

/* Camera Icon in Profile Picture */
const CameraIcon = styled(FaCamera)`
  font-size: 24px;
  color: ${({ theme }) => theme.textColor};
`;

/* User Info */
const UserInfo = styled.div`
  margin-left: 30px;
  color: ${({ theme }) => theme.textColor};
  display: flex;
  flex-direction: column;
`;

/* Stories Section */
const StoriesSection = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  overflow-x: auto;
`;

/* Gray Circle */
const GrayCircle = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${({ theme }) => theme.feedBorder};
  border-radius: 50%;
  position: relative;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

/* Plus Icon inside Gray Circle */
const PlusIcon = styled(FaPlus)`
  font-size: 24px;
  color: ${({ theme }) => theme.textColor};
`;

/* Caption under Gray Circle */
const Caption = styled.div`
  text-align: center;
  font-size: 12px;
  color: ${({ theme }) => theme.textColor};
  margin-top: 5px;
`;

/* Separator */
const Separator = styled.hr`
  width: 90%;
  border: none;
  border-top: 1px solid rgba(128, 128, 128, 0.2);
  margin: 10px 0;
`;

/* PostSavedPro Section */
const PostSavedProSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

/* Tabs Container */
const TabsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
`;

/* Tab Item */
const TabItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.textColor};

  &:hover {
    color: ${({ theme }) => theme.navLinkColor};
  }
`;

/* Tab Icon */
const TabIcon = styled.div`
  margin-right: 5px;
`;

/* Grid Container */
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns */
  grid-template-rows: repeat(3, 1fr);    /* 3 rows */
  gap: 1px; /* Gap between grid items */
  width: 100%;
  background-color: lightgray; /* The gaps will be light gray */
`;

/* Grid Item */
const GridItem = styled.div`
  background-color: darkgray;
  width: 100%;
  height: 100px; /* Adjust height as needed */
`;

const Account: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [profileImage, setProfileImage] = useState<string | null>(null);
 // Access user data from Clerk
 const { user, isLoaded, isSignedIn } = useUser();
 const navigate = useNavigate();

  const toggleNavbar = () => {
    setIsExpanded(!isExpanded);
  };

  const handleProfilePicClick = () => {
    // Trigger file input click
    document.getElementById('profilePicInput')?.click();
  };

  const handleProfilePicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfileImage(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };


// check if user is loaded
if (!isLoaded) {
  return <div>Loading...</div>; // Or a spinner/loading indicator
}

if (!isSignedIn) {
  navigate('/sign-in'); // Redirect to sign-in if not authenticated
  return null;
}

  return (
    <AccountContainer>
      <AccountNavbar isExpanded={isExpanded} toggleNavbar={toggleNavbar} />
      <ScrollableFeed>
        {/* Account Header Section */}
        <AccountHeaderSection>
          <ProfilePic onClick={handleProfilePicClick}>
            {profileImage ? (
              <img src={profileImage} alt="Profile" style={{ width: '100%', height: '100%' }} />
            ) : (
              <CameraIcon />
            )}
            <input
              type="file"
              id="profilePicInput"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleProfilePicChange}
            />
          </ProfilePic>
          <UserInfo>
            <h2>{user?.firstName || 'User'}</h2>
            <p>Bio</p>
          </UserInfo>
        </AccountHeaderSection>

        {/* Stories Section */}
        <StoriesSection>
          <div>
            <GrayCircle>
              <PlusIcon />
            </GrayCircle>
            <Caption>new</Caption>
          </div>
          {/* Additional stories can be added here */}
        </StoriesSection>

        {/* Separator */}
        <Separator />

        {/* PostSavedPro Section */}
        <PostSavedProSection>
          <TabsContainer>
            <TabItem>
              <TabIcon>
                <FaThList />
              </TabIcon>
              Posts
            </TabItem>
            <TabItem>
              <TabIcon>
                <FaBookmark />
              </TabIcon>
              Saved
            </TabItem>
            <TabItem>
              <TabIcon>
                <FaCrown />
              </TabIcon>
              Pro
            </TabItem>
          </TabsContainer>

          {/* Content for the selected tab */}
          <GridContainer>
            {Array.from({ length: 12 }).map((_, index) => (
              <GridItem key={index} />
            ))}
          </GridContainer>
        </PostSavedProSection>
      </ScrollableFeed>
    </AccountContainer>
  );
};

export default Account;
