// src/components/ThemeSelectionModal.tsx

import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../ThemeContex'; // Adjust the path as needed

const ModalOverlay = styled.div`
  /* Your existing styles */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 4000;
`;

const ModalContent = styled.div`
  /* Your existing styles */
  background-color: ${({ theme }) => theme.feedBackground};
  border: 1px solid ${({ theme }) => theme.feedBorder};
  color: ${({ theme }) => theme.textColor};
  padding: 1.5rem;
  border-radius: 8px;
  max-width: 300px;
  position: relative;
  margin: auto;
  top: 20%;
`;

interface ThemeSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ThemeSelectionModal: React.FC<ThemeSelectionModalProps> = ({ isOpen, onClose }) => {
  const { themeMode, setThemeMode } = useContext(ThemeContext);

  if (!isOpen) {
    return null;
  }

  const handleThemeChange = (mode: 'system' | 'light' | 'dark') => {
    setThemeMode(mode);
    onClose();
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <h3>Select Theme</h3>
        <div style={{ marginTop: '1rem' }}>
          <label style={{ display: 'block', marginBottom: '0.5rem' }}>
            <input
              type="radio"
              name="theme"
              value="system"
              checked={themeMode === 'system'}
              onChange={() => handleThemeChange('system')}
            />
            <span style={{ marginLeft: '0.5rem' }}>System Default</span>
          </label>
          <label style={{ display: 'block', marginBottom: '0.5rem' }}>
            <input
              type="radio"
              name="theme"
              value="light"
              checked={themeMode === 'light'}
              onChange={() => handleThemeChange('light')}
            />
            <span style={{ marginLeft: '0.5rem' }}>Light</span>
          </label>
          <label style={{ display: 'block', marginBottom: '0.5rem' }}>
            <input
              type="radio"
              name="theme"
              value="dark"
              checked={themeMode === 'dark'}
              onChange={() => handleThemeChange('dark')}
            />
            <span style={{ marginLeft: '0.5rem' }}>Dark</span>
          </label>
        </div>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ThemeSelectionModal;
