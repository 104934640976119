// src/pages/Login.tsx

import React from 'react';
import styled from 'styled-components';
import { SignIn as ClerkSignIn } from '@clerk/clerk-react'; // Alias Clerk's SignIn
import Footer from '../components/Footer';
import backgroundImage from '../assets/OlympicGold.webp';
import LogoContainerImage from '../assets/Couro.png';
import Navbar from '../components/Navbar';

// Styled Components
const LoginContainer = styled.div`
  height: 750px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
`;

const LoginInfoContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 40px;
  color: white;
  max-width: 380px;
  width: 45vw;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  @media (max-width: 768px) {
    width: 70vw;
  }
`;

const LogoContainer = styled.div`
  height: 100px;
  width: 120px;
  background-image: url(${LogoContainerImage});
  background-size: cover;
  background-position: center;
  margin-bottom: 1rem;
`;

const StyledClerkSignIn = styled(ClerkSignIn)`
  width: 100%;
  padding: 1rem;
  /* Add any additional custom styles here */
`;

const Login: React.FC = () => {
  return (
    <>
      <Navbar /> {/* Adjust props as needed */}
      <LoginContainer>
        <LoginInfoContainer>
          <LogoContainer />
          <StyledClerkSignIn
            path="/login"
            routing="path"
            fallbackRedirectUrl="/feed" 
            appearance={{
              elements: {
                formButtonPrimary:
                  'background-color: gold; color: white; border: none; border-radius: 10px; padding: 10px 20px;',
                // Customize other elements as needed
              },
            }}
          />
          {/* Optional: Add a Sign-Up Link or Additional Information */}
        </LoginInfoContainer>
      </LoginContainer>
      <Footer />
    </>
  );
};

export default Login;
