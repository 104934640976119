// src/components/AccountNavbar.tsx

import React, { useState } from 'react';
import styled from 'styled-components';
import LoggedInLogo from './LoggedInLogo';
import { useNavigate } from 'react-router-dom';
import { useClerk } from '@clerk/clerk-react';
import {
  FaUser,
  FaSearch,
  FaDumbbell,
  FaBars,
  FaCog,
  FaStar,
  FaPaintBrush,
  FaSignOutAlt,
  FaLaptop,
  FaBullhorn,
} from 'react-icons/fa';

// Import ThemeModal
import ThemeSelectionModal from './AccountNavbar/ThemeSelectionModal';

interface AccountNavbarProps {
  isExpanded: boolean;
  toggleNavbar: () => void;
}

/* Define MenuItemIcon and MenuItemText before they are used in MenuItem */
const MenuItemIcon = styled.div`
  font-size: 1.5rem;
  color: inherit;
`;

const MenuItemText = styled.span<{ isExpanded: boolean }>`
  display: ${({ isExpanded }) => (isExpanded ? 'inline' : 'none')};
  margin-left: 10px;
  font-size: 1rem;
  color: inherit;
`;

/* Define AccountPicIcon and AccountPicText */
const AccountPicIcon = styled.div`
  font-size: 1.5rem;
  color: inherit;
`;

const AccountPicText = styled.span<{ isExpanded: boolean }>`
  display: ${({ isExpanded }) => (isExpanded ? 'inline' : 'none')};
  margin-left: 10px;
  font-size: 1rem;
  color: inherit;
`;

const NavbarContainer = styled.nav<{ isExpanded: boolean }>`
  position: relative;
  height: 100vh;
  width: ${({ isExpanded }) => (isExpanded ? '225px' : '60px')};
  border-color: ${({ theme }) => theme.feedBorder}; /* Updated border color */
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 20px 0 20px; /* Removed bottom padding */
  border-right: 1px solid ${({ theme }) => theme.feedBorder}; /* Updated border color */
  transition: width 0.3s ease;
`;

const ExpandButton = styled.button`
  display: block;
  position: absolute;
  top: 10px;
  right: -5px;
  background-color: transparent;
  border: 1px solid rgb(195, 195, 195);
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.8rem;
  z-index: 2000;
  color: ${({ theme }) => theme.textColor};
`;

const AccountPic = styled.div<{ isExpanded: boolean }>`
  display: flex;
  align-items: center;
  width: ${({ isExpanded }) => (isExpanded ? '100%' : '40px')};
  height: 40px;
  margin-top: auto;
  color: ${({ theme }) => theme.textColor};
  cursor: pointer;
  margin-bottom: 3rem;
  padding-left: 1rem;

  &:hover ${AccountPicIcon}, &:hover ${AccountPicText} {
    color: gold;
    font-weight: bold;
  }
`;

const MenuItemsContainer = styled.div`
  margin-top: 2rem;
  width: 100%;
`;

const MenuItem = styled.div<{ isExpanded: boolean }>`
  display: flex;
  align-items: center;
  padding: 10px;
  color: ${({ theme }) => theme.textColor};
  cursor: pointer;

  &:hover ${MenuItemIcon}, &:hover ${MenuItemText} {
    color: gold;
    font-weight: bold;
  }
`;

const MenuModal = styled.div<{ isExpanded: boolean }>`
  position: absolute;
  bottom: 70px; /* Adjust as needed */
  left: ${({ isExpanded }) => (isExpanded ? '70px' : '55px')}; /* Adjust as needed */
  background-color: gray;
  padding: 10px;
  border-radius: 8px;
  color: white;
  z-index: 3000;
  width: ${({ isExpanded }) => (isExpanded ? '200px' : '150px')};
`;

const ModalMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  color: inherit;

  &:hover {
    color: gold;
  }

  span {
    margin-left: 10px;
  }
`;

const ModalOverlay = styled.div`
  /* Existing styles */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4000; /* Set higher z-index */
`;

const ModalContent = styled.div`
  /* Existing styles */
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  min-width: 300px;
  color: black;
  position: relative;
  margin: auto;
  top: 20%;
`;

const ModalButton = styled.button<{ isCancel?: boolean }>`
  /* Existing styles */
  background-color: ${({ isCancel }) => (isCancel ? '#ccc' : 'red')};
  color: ${({ isCancel }) => (isCancel ? '#000' : '#fff')};
  border: none;
  padding: 0.8rem 1.2rem;
  margin: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
`;

const AccountNavbar: React.FC<AccountNavbarProps> = ({ isExpanded, toggleNavbar }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isThemeModalOpen, setIsThemeModalOpen] = useState(false); // New state variable
  const navigate = useNavigate();
  const { signOut } = useClerk();

  // ... [Rest of your existing state variables and effects] ...

  const handleAccountPicClick = () => {
    setIsModalOpen(!isModalOpen);
    setShowConfirmation(false);
  };

  const handleLogoutClick = () => {
    setShowConfirmation(true);
  };

  const handleCancelLogout = () => {
    setShowConfirmation(false);
  };

  const handleConfirmLogout = async () => {
    await signOut();
    navigate('/login');
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setShowConfirmation(false);
    setIsThemeModalOpen(false); // Close the theme modal as well
  };

  return (
    <>
      <NavbarContainer isExpanded={isExpanded}>
        <LoggedInLogo />
        <ExpandButton onClick={toggleNavbar}>
          {isExpanded ? '<' : '>'}
        </ExpandButton>

        <MenuItemsContainer>
          <MenuItem isExpanded={isExpanded} onClick={() => navigate('/account')}>
            <MenuItemIcon>
              <FaUser />
            </MenuItemIcon>
            <MenuItemText isExpanded={isExpanded}>Account</MenuItemText>
          </MenuItem>

          {/* Changed "Settings" to "Search" */}
          <MenuItem isExpanded={isExpanded} onClick={() => navigate('/search')}>
            <MenuItemIcon>
              <FaSearch />
            </MenuItemIcon>
            <MenuItemText isExpanded={isExpanded}>Search</MenuItemText>
          </MenuItem>

          <MenuItem isExpanded={isExpanded} onClick={() => navigate('/workout-builder')}>
            <MenuItemIcon>
              <FaDumbbell />
            </MenuItemIcon>
            <MenuItemText isExpanded={isExpanded}>Workout Builder</MenuItemText>
          </MenuItem>

          {/* New Menu Item: Couro AI */}
          <MenuItem isExpanded={isExpanded} onClick={() => navigate('/couro-ai')}>
            <MenuItemIcon>
              <FaLaptop />
            </MenuItemIcon>
            <MenuItemText isExpanded={isExpanded}>Couro AI</MenuItemText>
          </MenuItem>

          {/* New Menu Item: Announcements */}
          <MenuItem isExpanded={isExpanded} onClick={() => navigate('/announcements')}>
            <MenuItemIcon>
              <FaBullhorn />
            </MenuItemIcon>
            <MenuItemText isExpanded={isExpanded}>Announcements</MenuItemText>
          </MenuItem>
        </MenuItemsContainer>


        {/* Changed to menu icon with text "More" */}
        <AccountPic isExpanded={isExpanded} onClick={handleAccountPicClick}>
          <AccountPicIcon>
            <FaBars />
          </AccountPicIcon>
          <AccountPicText isExpanded={isExpanded}>More</AccountPicText>
        </AccountPic>

        {isModalOpen && !showConfirmation && (
          <MenuModal isExpanded={isExpanded}>
            <ModalMenuItem onClick={() => { navigate('/settings'); closeModal(); }}>
              <FaCog />
              <span>Settings</span>
            </ModalMenuItem>
            <ModalMenuItem onClick={() => { /* Handle Go Pro action */ closeModal(); }}>
              <FaStar />
              <span>Go Pro</span>
            </ModalMenuItem>
            {/* Modify this menu item to open the theme modal */}
            <ModalMenuItem onClick={() => { setIsThemeModalOpen(true); setIsModalOpen(false); }}>
              <FaPaintBrush />
              <span>Theme</span>
            </ModalMenuItem>
            <ModalMenuItem onClick={handleLogoutClick}>
              <FaSignOutAlt />
              <span>Sign out</span>
            </ModalMenuItem>
          </MenuModal>
        )}

        {showConfirmation && (
          <ModalOverlay onClick={closeModal}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
              <p>Are you sure you would like to sign out?</p>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ModalButton onClick={handleConfirmLogout}>Yes</ModalButton>
                <ModalButton isCancel onClick={handleCancelLogout}>
                  No
                </ModalButton>
              </div>
            </ModalContent>
          </ModalOverlay>
        )}

       {/* Theme Selection Modal */}
      <ThemeSelectionModal isOpen={isThemeModalOpen} onClose={closeModal} />

      </NavbarContainer>
    </>
  );
};

export default AccountNavbar;
