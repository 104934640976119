import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import HeroImage from '../../assets/HeroImage.png';

const SectionContainer = styled.div`
  height: 600px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem;
`;

const LeftContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
`;

const RightContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 80%;
    @media (min-width: 768px) {
      width: 80%;
    }
  }
`;

const Heading = styled.h1`
  margin-bottom: 1rem;
  padding-horizontal: 3rem;
  font-size: 2.5rem;
`;

const SubHeading = styled.p`
  margin-bottom: 2rem;
  font-size: 1rem;
  font-weight: semi-bold;
  padding-horizontal: 5rem;
`;

const TryNowButton = styled.button`
  background-color: #ffc700;
  color: #000;
  border: none;
  padding: 1rem 2rem;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e6b800;
  }
`;

const HeroSection: React.FC = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/home');
  };

  return (
    <SectionContainer>
      <LeftContainer>
        <Heading>Say Hello to our Run Analysis Tool</Heading>
        <SubHeading>
          Couro is the world’s first mobile run analysis tool. You can use it to
          scan, track, and assess your running angles, efficiency, and more with
          a few clicks.
        </SubHeading>
        <TryNowButton onClick={handleButtonClick}>Try now</TryNowButton>
      </LeftContainer>
      <RightContainer>
        <img src={HeroImage} alt="Hero Section" />
      </RightContainer>
    </SectionContainer>
  );
};

export default HeroSection;