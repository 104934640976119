// src/pages/SignUp.tsx

import React from 'react';
import styled from 'styled-components';
import { SignUp as ClerkSignUp } from '@clerk/clerk-react'; // Alias Clerk's SignUp
import Footer from '../components/Footer';
import backgroundImage from '../assets/OlympicGold.webp';
import LogoContainerImage from '../assets/Couro.png';
import Navbar from '../components/Navbar';

// Styled Components
const SignUpContainer = styled.div`
  height: 750px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
`;

const SignUpInfoContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 40px;
  color: white;
  max-width: 380px;
  width: 45vw;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  @media (max-width: 768px) {
    width: 70vw;
  }
`;

const LogoContainer = styled.div`
  height: 100px;
  width: 120px;
  background-image: url(${LogoContainerImage});
  background-size: cover;
  background-position: center;
  margin-bottom: 1rem;
`;

const SignUpPage: React.FC = () => {
  return (
    <>
      <Navbar />
      <SignUpContainer>
        <SignUpInfoContainer>
          <LogoContainer />
          <ClerkSignUp
            path="/signup"
            routing="path"
            signInUrl="/login"
            afterSignUpUrl="/account"
            appearance={{
              elements: {
                formButtonPrimary:
                  'background-color: gold; color: white; border: none; border-radius: 10px; padding: 10px 20px;',
                // Customize other elements as needed
              },
            }}
          />
        </SignUpInfoContainer>
      </SignUpContainer>
      <Footer />
    </>
  );
};

export default SignUpPage;
