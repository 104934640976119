// src/pages/UserProfile.tsx
import React from 'react';
import { useParams } from 'react-router-dom';
import { authors } from '../data/authors';
import styled from 'styled-components';
import AccountNavbar from '../components/AccountNavbar';
import { FaThList, FaBookmark, FaCrown } from 'react-icons/fa';

const AccountContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.feedBackground};
`;

const ScrollableFeed = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.feedBackground};
`;

/* Account Header Section */
const AccountHeaderSection = styled.div`
  width: 80%;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2.5rem 3rem 1rem 3rem;
`;

/* Profile Picture */
const ProfilePic = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.feedBorder};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 1rem;
`;

/* User Info */
const UserInfo = styled.div`
  margin-left: 30px;
  color: ${({ theme }) => theme.textColor};
  display: flex;
  flex-direction: column;
`;

/* Stories Section */
const StoriesSection = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  overflow-x: auto;
`;

/* Separator */
const Separator = styled.hr`
  width: 90%;
  border: none;
  border-top: 1px solid rgba(128, 128, 128, 0.2);
  margin: 10px 0;
`;

/* PostSavedPro Section */
const PostSavedProSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

/* Tabs Container */
const TabsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
`;

/* Tab Item */
const TabItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.textColor};

  &:hover {
    color: ${({ theme }) => theme.navLinkColor};
  }
`;

/* Tab Icon */
const TabIcon = styled.div`
  margin-right: 5px;
`;

/* Grid Container */
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns */
  grid-template-rows: auto;
  gap: 1px; /* Gap between grid items */
  width: 100%;
  background-color: lightgray; /* The gaps will be light gray */
`;

/* Grid Item */
const GridItem = styled.div`
  background-color: darkgray;
  width: 100%;
  height: 100px; /* Adjust height as needed */
`;

const UserProfile: React.FC = () => {
  const { username } = useParams<{ username: string }>();

  // List of reserved usernames to avoid conflicts
  const reservedUsernames = [
    'login',
    'signup',
    'account',
    'search',
    'feed',
    'settings',
    'couro-ai',
    'announcements',
    'workout-builder',
    // Add other reserved paths
  ];

  if (!username || reservedUsernames.includes(username.toLowerCase())) {
    return <div>User not found</div>;
  }

  // Find the author with the matching username
  const user = authors.find((author) => author.username === username);

  if (!user) {
    return <div>User not found</div>;
  }

  const profileImage = user.iconUrl;

  return (
    <AccountContainer>
      <AccountNavbar isExpanded={false} toggleNavbar={() => {}} />
      <ScrollableFeed>
        {/* Account Header Section */}
        <AccountHeaderSection>
          <ProfilePic>
            {profileImage ? (
              <img src={profileImage} alt="Profile" style={{ width: '100%', height: '100%' }} />
            ) : (
              <div>No Image</div>
            )}
          </ProfilePic>
          <UserInfo>
            <h2>{user.username}</h2>
            <p>Bio</p>
          </UserInfo>
        </AccountHeaderSection>

        {/* Stories Section */}
        <StoriesSection>
          {/* You can add content here or remove the section if not needed */}
        </StoriesSection>

        {/* Separator */}
        <Separator />

        {/* PostSavedPro Section */}
        <PostSavedProSection>
          <TabsContainer>
            <TabItem>
              <TabIcon>
                <FaThList />
              </TabIcon>
              Posts
            </TabItem>
            <TabItem>
              <TabIcon>
                <FaBookmark />
              </TabIcon>
              Saved
            </TabItem>
            {user.isPro && (
              <TabItem>
                <TabIcon>
                  <FaCrown />
                </TabIcon>
                Pro
              </TabItem>
            )}
          </TabsContainer>

          {/* Content for the selected tab */}
          <GridContainer>
            {/* Replace with user's actual content */}
            {Array.from({ length: 12 }).map((_, index) => (
              <GridItem key={index} />
            ))}
          </GridContainer>
        </PostSavedProSection>
      </ScrollableFeed>
    </AccountContainer>
  );
};

export default UserProfile;
