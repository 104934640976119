// src/pages/Feed.tsx

import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import AccountNavbar from '../components/AccountNavbar';
import Post from '../components/Feed/Post';
import FollowingList from '../components/Feed/FollowingList';
import CouroAi from '../assets/CouroAI/Couroai.webp';
import { Link } from 'react-router-dom';

// Styled Components

const AccountContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.feedBackground};
`;

const ScrollableFeed = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.feedBackground};
`;

const AdContainer = styled.div`
  flex-basis: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  /* Hide on mobile devices */
  @media (max-width: 768px) {
    display: none;
  }
`;

const PromoBox = styled.div`
  width: 90%;
  height: 100px;
  border: 2px solid gold;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.feedBackground};

  &:hover {
    background-color: ${({ theme }) => theme.feedHover};
  }
`;

const PromoText = styled.p`
  text-align: center;
  font-size: 1.3rem;
  font-weight: 600; /* Changed from 'semi-bold' to numeric value */
  color: ${({ theme }) => theme.textColor};
`;

const GrayBox = styled.div`
  width: 90%;
  height: 600px;
  background-image: url(${CouroAi});
  background-size: cover;        /* Scales the image to cover the container */
  background-position: center;   /* Centers the image */
  background-repeat: no-repeat;  /* Prevents the image from repeating */
  border-radius: 10px;
  margin-top: 1rem;
  position: relative;            /* Establishes a positioning context for TryNowButton */

  @media (max-width: 768px) {
    width: 100%;
    height: 400px;               /* Adjust height for mobile devices */
    border-radius: 0;            /* Optional: remove border radius on mobile */
    background-size: contain;    /* Ensures the entire image is visible */
    background-color: gray;      /* Fallback color to fill any empty space */
  }
`;

// TryNowButton styled-component
const TryNowButton = styled(Link)`
  position: absolute;
  bottom: 1.2rem;
  left: 0.25rem;
  padding: 1rem 1.4rem;
  background-color: gold;
  color: #000; /* Black text for contrast */
  border: none;
  border-radius: 12px;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: darkgoldenrod; /* Darker gold on hover */
    transform: scale(1.05);          /* Slightly enlarge the button */
  }

  &:focus {
    outline: 2px solid #000;         /* Black outline on focus for accessibility */
  }
`;

/* Button Container */
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  border: 0.7px solid ${({ theme }) => theme.feedBorder};
  border-top: none;
  margin-bottom: 1rem;
  position: relative; /* Add this to position the underline */
`;

/* Underline styled-component with dynamic props */
const Underline = styled.div<{ left: number; width: number }>`
  position: absolute;
  bottom: 0;
  left: ${(props) => props.left}px;
  width: ${(props) => props.width}px;
  height: 2px;
  background-color: #87CEEB;
  transition: all 0.3s ease;
`;

/* Tab Button Styles */
const TabButton = styled.button<{ active: boolean }>`
  flex: 1;
  background-color: transparent;
  border: none;
  color: ${({ theme, active }) => (active ? theme.navLinkColor : theme.textColor)};
  padding: 1rem;
  cursor: pointer;
  font-size: 1rem;
  position: relative;
  transition: color 0.3s;
  font-weight: bold;

  &:hover {
    color: ${({ theme }) => theme.navLinkColor};
  }
`;

// Optional: Define ExploreContent and FollowingContent styled-components for better semantics
const ExploreContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;

  /* Add any additional styles specific to Explore content here */
`;

const FollowingContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 1rem 0;

  /* Add any additional styles specific to Following content here */
`;

// Define a type for tab names
type TabName = 'Explore' | 'Following';

const Feed: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Initialize selectedTab from localStorage
  const initialTab = useMemo(() => {
    const savedTab = localStorage.getItem('selectedTab');
    return savedTab === 'Explore' || savedTab === 'Following' ? (savedTab as TabName) : 'Explore';
  }, []);

  const [selectedTab, setSelectedTab] = useState<TabName>(initialTab);
  const [underlineProps, setUnderlineProps] = useState({ left: 0, width: 0 });

  // Refs for the tab buttons
  const exploreRef = useRef<HTMLButtonElement>(null);
  const followingRef = useRef<HTMLButtonElement>(null);

  const tabRefs = useMemo(
    () => ({
      Explore: exploreRef,
      Following: followingRef,
    }),
    []
  );

  const toggleNavbar = () => {
    setIsExpanded(!isExpanded);
  };

  // Update underline position when selectedTab changes
  useEffect(() => {
    const updateUnderline = () => {
      const currentTab = tabRefs[selectedTab].current;
      if (currentTab) {
        setUnderlineProps({
          left: currentTab.offsetLeft,
          width: currentTab.offsetWidth,
        });
      }
    };
    updateUnderline();

    // Recalculate on window resize to handle responsive layouts
    window.addEventListener('resize', updateUnderline);
    return () => {
      window.removeEventListener('resize', updateUnderline);
    };
  }, [selectedTab, tabRefs]);

  // Save selectedTab to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('selectedTab', selectedTab);
  }, [selectedTab]);

  // Debugging: Log selectedTab changes
  useEffect(() => {
    console.log('Selected Tab:', selectedTab);
  }, [selectedTab]);

  return (
    <AccountContainer>
      <AccountNavbar isExpanded={isExpanded} toggleNavbar={toggleNavbar} />
      <ScrollableFeed>
        {/* Button Container */}
        <ButtonContainer>
          <TabButton
            ref={exploreRef}
            active={selectedTab === 'Explore'}
            onClick={() => setSelectedTab('Explore')}
          >
            Explore
          </TabButton>
          <TabButton
            ref={followingRef}
            active={selectedTab === 'Following'}
            onClick={() => setSelectedTab('Following')}
          >
            Following
          </TabButton>
          {/* Underline */}
          <Underline left={underlineProps.left} width={underlineProps.width} />
        </ButtonContainer>

        {/* Conditionally render content based on selectedTab */}
        {selectedTab === 'Explore' && (
          <ExploreContent>
            <Post />
            <Post />
            <Post />
            <Post />
          </ExploreContent>
        )}
        {selectedTab === 'Following' && (
          <FollowingContent>
            <FollowingList />
            <Post />
            <Post />
          </FollowingContent>
        )}
      </ScrollableFeed>

      {/* Ad Container */}
      <AdContainer>
        <PromoBox>
          <PromoText>Want to be a pro?</PromoText>
        </PromoBox>
        <GrayBox>
          <TryNowButton to="/">Try Now</TryNowButton>
        </GrayBox>
      </AdContainer>
    </AccountContainer>
  );
};

export default Feed;
