// src/pages/Search.tsx

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import AccountNavbar from '../components/AccountNavbar';
import { FaUserCircle } from 'react-icons/fa';
import { useUser } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';

// Styled Components

const SearchContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.feedBackground};
`;

const SearchList = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Removed overflow-y: auto to make it non-scrollable */
  background-color: ${({ theme }) => theme.feedBackground};
  padding: 2rem;
`;

/* Search Input */
const SearchInput = styled.input`
  width: 80%;
  max-width: 800px;
  padding: 0.8rem 1.2rem;
  border: 1px solid ${({ theme }) => theme.feedBorder};
  background-color: ${({ theme }) => theme.feedBackground};
  border-radius: 25px;
  font-size: 1rem;
  margin-bottom: 2rem;
  outline: none;
  color: ${({ theme }) => theme.textColor};

  &:focus {
    border-color: ${({ theme }) => theme.navLinkColor};
    box-shadow: 0 0 5px ${({ theme }) => theme.navLinkColor};
  }
`;

/* Search Result Item */
const SearchResultItem = styled.div`
  width: 80%;
  max-width: 800px;
  display: flex;
  align-items: center;
  padding: 0.8rem;
  border: 1px solid ${({ theme }) => theme.feedBorder};
  border-radius: 10px;
  margin-bottom: 1rem;
  background-color: ${({ theme }) => theme.background};
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${({ theme }) => theme.feedHover};
  }
`;

/* Profile Icon */
const ProfileIcon = styled.div`
  font-size: 2rem;
  color: ${({ theme }) => theme.textColor};
  margin-right: 1rem;
`;

/* User Name */
const UserName = styled.div`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.textColor};
`;

/* Optional: No Results Found */
const NoResults = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.textColor};
`;

const Search: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredAccounts, setFilteredAccounts] = useState<Array<{ id: string; name: string }>>([]);

  const navigate = useNavigate();

  // Example saved accounts data
  const savedAccounts = [
    { id: '1', name: 'John Doe' },
    { id: '2', name: 'Jane Smith' },
    { id: '3', name: 'Alice Johnson' },
    { id: '4', name: 'Bob Brown' },
    // Add more saved accounts as needed
  ];

  // Access user data from Clerk
  const { user, isLoaded, isSignedIn } = useUser();

  // Redirect to sign-in if not authenticated
  useEffect(() => {
    if (isLoaded && !isSignedIn) {
      navigate('/sign-in');
    }
  }, [isLoaded, isSignedIn, navigate]);

  const toggleNavbar = () => {
    setIsExpanded(!isExpanded);
  };

  // Handle search query changes
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query.trim() === '') {
      setFilteredAccounts([]);
    } else {
      const filtered = savedAccounts.filter((account) =>
        account.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredAccounts(filtered);
    }
  };

  const handleResultClick = (accountId: string) => {
    // Navigate to the account's profile page or perform desired action
    navigate(`/account/${accountId}`);
  };

  // Check if user is loaded
  if (!isLoaded) {
    return <div>Loading...</div>; // Or a spinner/loading indicator
  }

  return (
    <SearchContainer>
      <AccountNavbar isExpanded={isExpanded} toggleNavbar={toggleNavbar} />
      <SearchList>
        {/* Search Input */}
        <SearchInput
          type="text"
          placeholder="Search saved accounts..."
          value={searchQuery}
          onChange={handleSearchChange}
        />

        {/* Search Results */}
        {searchQuery && (
          filteredAccounts.length > 0 ? (
            <div style={{ width: '80%', maxWidth: '700px' }}>
              {filteredAccounts.map((account) => (
                <SearchResultItem key={account.id} onClick={() => handleResultClick(account.id)}>
                  <ProfileIcon>
                    <FaUserCircle />
                  </ProfileIcon>
                  <UserName>{account.name}</UserName>
                </SearchResultItem>
              ))}
            </div>
          ) : (
            <NoResults>No matching accounts found.</NoResults>
          )
        )}
      </SearchList>
    </SearchContainer>
  );
};


export default Search;
