// index.tsx

import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import
import { ClerkProvider } from '@clerk/clerk-react';
import App from './App';



const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
// For Supabase
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!clerkPubKey) {
  throw new Error('Missing Clerk publishable key');
}

const container = document.getElementById('root');

if (!container) {
  throw new Error('Root container not found');
}

// Create a root.
const root = ReactDOM.createRoot(container);

// Initial render.
root.render(
  <React.StrictMode>
    <ClerkProvider publishableKey={clerkPubKey}>
      <App />
    </ClerkProvider>
  </React.StrictMode>
);
